// Libs
import React, { useMemo } from 'react';
// Assets
import styles from './video-hero.module.scss';
import * as PropTypes from 'prop-types';
// Components
import CommonVideo from '../video';
import Img from 'gatsby-image';
import ListingTitle from '../listing-title/listing-title';

const VideoHero = ({ content, video, image }) => {
  // The 'video' variable should contain the object with structure as on an example below.
  //
  // const video = {
  //   source: [
  //     { src: '/video.webm', type: 'video/webm' },
  //     { src: '/video.ogg', type: 'video/ogg' },
  //     { src: '/video.mp4', type: 'video/mp4' },
  //   ],
  // };
    const videoConfig = {
        playsinline: true,
    };

  return useMemo(
    () => (
      <div className={styles.videoHero}>
        {image ? <Img fluid={image.fluid}/> : <CommonVideo video={video} config={videoConfig}/>}
        <ListingTitle content={content} />
      </div>
    ),
    [content, video]
  );
};

VideoHero.defaultProps = {
  content: PropTypes.isObject,
  video: PropTypes.isObject,
};

export default VideoHero;
