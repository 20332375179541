// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
// Deps
import Img from 'gatsby-image';
import { has } from 'lodash';
//Assets.
import imgPlaceholder from '../../images/class_hero_image.png';
import styles from './loft-card.module.scss';
// Hooks.
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import SiteLink from './site-link';

const LoftCard = ({ card, target = '_self' }) => {
  let imageSource;
  const cardImageHook = useImageStaticQuery(has(card, 'image') ? card.image : 'class_hero_image.png');
  const cardImage = has(card, 'image.fluid') ? card.image : cardImageHook;
  if (has(cardImage, 'childImageSharp.fluid')) {
    imageSource = <Img className={styles.loftCardImage} fluid={cardImage.childImageSharp.fluid} />;
  } else if (has(cardImage, 'fluid')) {
    imageSource = <Img className={styles.loftCardImage} fluid={cardImage.fluid} />;
  }
  else {
    imageSource = (
      <img className={styles.loftCardItemImage} src={imgPlaceholder} alt="Placeholder" />
    );
  }

  let cardTitle = (
    <>
      <h4 className={styles.loftCardTitle}>{card.title}</h4>
      {has(card, 'subtitle') && <h4 className={styles.loftCardSubtitle}>{card.subtitle}</h4>}
    </>
  );
  if (has(card, 'link.uri') && card.link.uri !== '') {
    imageSource = <SiteLink href={card.link.uri}>{imageSource}</SiteLink>;
    cardTitle = (
      <SiteLink href={card.link.uri} className={styles.loftCardInlineLink}>
        {cardTitle}
      </SiteLink>
    );
  }

  const cardContent = has(card, 'storeRelatedNotes')
    ? card.storeRelatedNotes
    : has(card, 'description')
    ? card.description
    : '';

  return (
    <div className={styles.loftCard}>
      {imageSource}
      <div className={styles.loftCardInfoContainer}>
        {cardTitle}
        <div className={styles.loftCardText} dangerouslySetInnerHTML={{__html: cardContent}}/>
        {has(card, 'link.uri') && card.link.uri !== '' && (
          <div className={styles.loftCardLink}>
            <SiteLink href={card.link.uri} className={styles.loftCardInlineLink} target={target}>
              {card.link.title}
            </SiteLink>
          </div>
        )}
      </div>
    </div>
  );
};

LoftCard.defaultProps = {
  classDetails: null,
};

LoftCard.propTypes = {
  classDetails: PropTypes.object,
};

export default LoftCard;
