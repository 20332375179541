import { useStaticQuery, graphql } from 'gatsby';
import {drupalTransformer} from '../libs/transform/index'

export const useCookingClassesQuery = () => {
  return drupalTransformer.transformNode(useCookingClassesPage());
};

const useCookingClassesPage = () => {
  return useStaticQuery(graphql`
    query CookingClassesLoft {
      configPagesCookingClasses {
        field_cta_title
        field_hte_item_description
        field_hte_item_description_2
        field_hte_item_description_3
        field_feature_link {
            title
            uri
        }
        field_feature_link_2 {
            title
            uri
        }
        field_feature_link_3 {
            title
            uri
        }
        field_hte_item_title
        field_hte_item_title_2
        field_hte_item_title_3
        relationships {
            field_hte_item_image {
                localFile {
                    childImageSharp {
                        fluid {
                            srcSet
                            src
                            base64
                            aspectRatio
                            sizes
                        }
                    }
                }
            }
            field_hte_item_image_2 {
                localFile {
                    childImageSharp {
                        fluid {
                            srcSet
                            src
                            base64
                            aspectRatio
                            sizes
                        }
                    }
                }
            }
            field_hte_item_image_3 {
                localFile {
                    childImageSharp {
                        fluid {
                            srcSet
                            src
                            base64
                            aspectRatio
                            sizes
                        }
                    }
                }
            }
        }
      }
    }
  `);
};
