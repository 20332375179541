// Libs
import React, { useState, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { uniqueId, defaultsDeep } from 'lodash';
// Deps
import ReactPlayer from 'react-player';
import { isMobileOnly } from 'react-device-detect';
//Assets.
import styles from './video.module.scss';
import { pushEvent } from '../../libs/dataLayer';

const defaultVideoOptions = () => {
  return {
    loop: true,
    muted: true,
    playing: !isMobileOnly,
    width: '100%',
    height: 'auto',
    playsinline: false,
  };
};

/**
 * A simple video player for use around the site.
 *
 * @param video
 * @param options
 * @param children
 * @returns {*}
 * @constructor
 */
const CommonVideo = ({ video, config, children }) => {
  // Ensure we have valid options
  const [opts, setOpts] = useState(defaultsDeep(config, { id: uniqueId('video_') }, defaultVideoOptions()));

  // Ensure props confirm properly.
  defaultsDeep(video, {
    source: [],
  });

  /**
   * Method to allow the video to pause or resume playing.
   */
  const pausePlayVideo = () => {
    const newOpts = { ...opts, playing: !opts.playing };
    // console.log('playing should be', newOpts);
    setOpts(newOpts);
  };

  return useMemo(
    () => (
      <div className={styles.commonVideo}>
        <div className={styles.commonVideoPausePlay}>
          <button className={styles.commonVideoButtons} onClick={pausePlayVideo}>
            {opts.playing ? 'pause_circle_outline' : 'play_circle_outline'}
          </button>
        </div>
        <div className={styles.commonVideoLogoOverlay}></div>
        <div className={styles.commonVideoPlayerContainer}>
          <ReactPlayer
            id={opts.id}
            className={styles.commonVideoPlayer}
            loop={opts.loop}
            muted={opts.muted}
            playing={opts.playing}
            width={opts.width}
            height={opts.height}
            url={video.source}
            playsinline={opts.playsinline}
            onStart={() => { pushEvent('interaction', 'video','header video played')}}
          />
        </div>
        {children}
      </div>
    ),
    [video, opts],
  );
};

CommonVideo.defaultProps = {
  video: null,
  children: null,
  config: defaultVideoOptions(),
};

CommonVideo.propTypes = {
  video: PropTypes.object,
  config: PropTypes.shape({
    playing: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    playsinline: PropTypes.bool,
  }),
  children: PropTypes.node,
};

export default CommonVideo;