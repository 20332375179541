// Libs
import React from 'react';
import loadable from '@loadable/component';
// State
import { useClassesStaticQuery } from '../../hooks/useClassesStaticQuery';
// Deps
import VideoHero from '../common/video-hero/video-hero';
import ClassListingFeatured from './class-listing-featured';
import CookingLoftList from './class-loft-list';
import StaticContentList from '../common/static-content/static-content-list';
// Assets
import styles from './class-listing.module.scss';
import locale from '../../locale/locale';
import { getContentSubItems } from '../../libs/static-content/static-content-utils';
import PagePreloader from '../common/loaders/page-preloader';
import {useFAQQuery} from "../../hooks/useFAQPageQuery";
const CookingClassesList = loadable(() => import('./class-cooking-list'), {
  fallback: (
    <div style={{ position: 'relative' }}>
      <PagePreloader />
    </div>
  ),
});
const ClassListing = ({ featuredClassId, content }) => {
  const {image} = content;
  const {configPagesFaq: {items: faqs} } = useFAQQuery();
  // State
  const [{ byClassNid }] = useClassesStaticQuery();
  // Local data
  const featuredClass = byClassNid.find((Class) => {return Class.class.nid === featuredClassId});
  // console.log('featuredClass', featuredClass);
  // TODO: Place holder awaiting content integration...
  const video = {
    source: [
      { src: '/assets/videos/LongosLoft/LongosLoft.webm', type: 'video/webm' },
      { src: '/assets/videos/LongosLoft/LongosLoft.ogg', type: 'video/ogg' },
      { src: '/assets/videos/LongosLoft/LongosLoft.mp4', type: 'video/mp4' },
    ],
  };

  // Render
  return (
    <div className={styles.classListing}>
      <div className={styles.classListingContentBackground}>
        <VideoHero video={video} content={content} image={image} />
        {featuredClass && <ClassListingFeatured classSchedule={featuredClass} />}
        <CookingClassesList />
        <CookingLoftList />
      </div>
      <div className={styles.classListingWhiteWrapper}>
        <StaticContentList
        listingTitle={locale.faqs.title}
        items={getContentSubItems(faqs, 'Loft Cooking School', 3)}
        showViewAll={true}
        numItemsPerLine="3"
        viewAllLink="/faqs"
      />
      </div>
    </div>
  );
};

export default ClassListing;
