import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import ClassListing from '../components/class/class-listing';
import { formatFieldsValue } from '../libs/formatFromGraph';

const CookingClassesPage = ({ data }) => {
  const pageData = formatFieldsValue(data)['configPagesCookingClasses'];
  const content = { title: pageData.heroTitle, body: pageData.heroBody, image: pageData.field_hero_behavior === 'default' ? null : pageData.image };
  return (
    <Layout>
      <SEO title="Cooking Classes" />
      <ClassListing featuredClassId={pageData.featuredClass.nid} content={content} />
    </Layout>
  );
};

export default CookingClassesPage;

export const query = graphql`
  query {
    configPagesCookingClasses {
      heroTitle: field_loft_hero_title
      heroBody: field_loft_hero_body {
        value
      }
      field_hero_behavior
      relationships {
        featuredClass: field_featured_class {
          nid: drupal_internal__nid
        }
        image:field_hero_image {
          localFile {
            childImageSharp {
              fluid {
                srcSet
                src
                base64
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
