// Libs
import React from 'react';
import LoftCard from '../common/loft-card';
//Assets.
import styles from './class-loft-list.module.scss';
import {useCookingClassesQuery} from "../../hooks/useCookingClassesQuery";

const CookingLoftList = () => {
  const {configPagesCookingClasses: lofList} = useCookingClassesQuery();
  const newItems = [
    {
      id: 1,
      image: lofList.hteItemImage,
      link: lofList.featureLink,
      storeRelatedNotes: lofList.hteItemDescription,
      title: lofList.hteItemTitle,
    },
    {
      id: 2,
      image: lofList.hteItemImage2,
      link: lofList.featureLink2,
      storeRelatedNotes: lofList.hteItemDescription2,
      title: lofList.hteItemTitle2,
    },
    {
      id: 3,
      image: lofList.hteItemImage3,
      link: lofList.featureLink3,
      storeRelatedNotes: lofList.hteItemDescription3,
      title: lofList.hteItemTitle3,
    }
  ]

  return (
    <div className={styles.classLoftList}>
      <div className={styles.classLoftListContainer}>
        <h2 className={styles.classLoftListTitle}>{lofList.ctaTitle}</h2>
        <div className={styles.classLoftListItemsContainer}>
          {newItems.map((loft, i) => {
            return <LoftCard card={loft} key={i} target="_blank"/>;
          })}
        </div>
      </div>
    </div>
  );
};

export default CookingLoftList;
